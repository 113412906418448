<template>
    <AppMultiselect v-bind="$attrs" v-model="value" :service="serviceAtividadeEspecifica" optionLabel="nome" />
</template>
<script>
import SesmtService from '@/services/SesmtService';

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            serviceAtividadeEspecifica: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.serviceAtividadeEspecifica = new SesmtService('/atividades-especificas');
    }
};
</script>
